import firebase from "firebase";

// Initialize Firebase
const config = {
  apiKey: "AIzaSyBKPdodEqJa51qWUOhDmlutsBDeGmba8k0",
  authDomain: "eumpire-2020.firebaseapp.com",
  databaseURL: "https://eumpire-2020.firebaseio.com",
  projectId: "eumpire-2020",
  storageBucket: "eumpire-2020.appspot.com",
  messagingSenderId: "748062287823",
};

firebase.initializeApp(config);
const auth = firebase.auth();

const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
const githubAuthProvider = new firebase.auth.GithubAuthProvider();
const twitterAuthProvider = new firebase.auth.TwitterAuthProvider();

const database = firebase.database();


export {
  database,
  auth,
  googleAuthProvider,
  githubAuthProvider,
  facebookAuthProvider,
  twitterAuthProvider
};

import
  {
    FETCH_PLAN,
    FETCH_ACCOUNTS,
    FETCH_ALL_CUSTOMERS,
    FETCH_ACCOUNT_PLAN,
    FETCH_EMAIL
  } from "../../constants/ActionTypes";
import { formatTableInfo, getOfferDetails, getFeatures, isDiscounted, listDiscounts } from '../../util/formatPlan';
import { auth } from "../../firebase/firebase";


const initialPlan = {
  loading: true,
  account_name: "Best Plan",
  energytype: "",
  elec_details: [],
  elec_config: [],
  offer_details: [],
  isDiscounted: [],
  listDiscounts: [],
  features: [],
  receiveAccount: false,
  accountsInfo: [],
  allCustomersInfo: {},
  switchPlanInfo: {},
  email: '',
  mailgun: '',
}

const Fetch = (state = initialPlan, action) =>
{
  switch (action.type)
  {
    case FETCH_PLAN:
      let res = action.planInfo;
      console.log("plan info")
      console.log(res)
      let elec_details = formatTableInfo(res);
      let energytype = '';
      if (res.energytype === "ELEC")
      {
        energytype = "Electricity"
      } else
      {
        energytype = "Gas"
      }
      //fetch the info for Switch Plan
      let infoForSwitchPlan = {
        signUpUrl: res._retailer.signup_url,
        retailerName: res._retailer.name,
        retailerPhone: res._retailer.phone,
        planName: res.name,
        energyType: res.energytype
      }

      return {
        ...state,
        loading: false,
        energytype: energytype,
        elec_details: elec_details.tabs,
        elec_config: elec_details.config,
        offer_details: getOfferDetails(res),
        features: getFeatures(res),
        isDiscounted: isDiscounted(res.pricing),
        listDiscounts: listDiscounts(res.pricing),
        switchPlanInfo: { ...state.switchPlanInfo, ...infoForSwitchPlan }
      };
    case FETCH_ACCOUNTS:
      let rawAccounts = action.accountsInfo;
      let accountsInfo = []
      rawAccounts.map(account =>
      {
        let accountDetails = {};
        accountDetails["id"] = account._id;
        accountDetails["name"] = account.energytype + ' - ' + account.contractname
        accountDetails["plan"] = account.energyplans[0].plan
        accountsInfo.push(accountDetails)
      })
      return {
        ...state,
        receiveAccount: true,
        accountsInfo: accountsInfo
      };
    case FETCH_ALL_CUSTOMERS:
      let allCustomersInfo = action.allCustomersInfo;
      return {
        ...state,
        allCustomersInfo: allCustomersInfo
      };

    case FETCH_ACCOUNT_PLAN:
      const accountId = action.accountId;
      const account = state.accountsInfo.filter(account => account.id === accountId)[0];
      const account_name = account.name;
      const plan = account.plan;

      console.log("plan--->>>", plan)
      let plan_details = formatTableInfo(plan);
      return {
        ...state,
        loading: false,
        account_name: account_name,
        elec_details: plan_details.tabs,
        elec_config: plan_details.config,
        offer_details: getOfferDetails(plan),
        features: getFeatures(plan),
      };

    case FETCH_EMAIL:
      const mailInfo = action.emailInfo
      return {
        ...state,
        email: mailInfo.email,
        mailgun: mailInfo.mailgun_email,
      }

    default:
      return state;
  }
}

export default Fetch;

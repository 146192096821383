import React from "react";
import {useSelector} from "react-redux";
import {Button, Menu} from "antd";
import {Link} from "react-router-dom";
import IntlMessages from "../../util/IntlMessages";
import {
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL
} from "../../constants/ThemeSetting";


const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

const HorizontalNav_v1 = () => {

  const navStyle = useSelector(({settings}) => settings.navStyle);
  const pathname = useSelector(({settings}) => settings.pathname);

  const getNavStyleSubMenuClass = (navStyle) => {
    switch (navStyle) {
      case NAV_STYLE_DEFAULT_HORIZONTAL:
        return "gx-menu-horizontal gx-submenu-popup-curve";
      case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
        return "gx-menu-horizontal gx-submenu-popup-curve gx-inside-submenu-popup-curve";
      case NAV_STYLE_BELOW_HEADER:
        return "gx-menu-horizontal gx-submenu-popup-curve gx-below-submenu-popup-curve";
      case NAV_STYLE_ABOVE_HEADER:
        return "gx-menu-horizontal gx-submenu-popup-curve gx-above-submenu-popup-curve";
      default:
        return "gx-menu-horizontal";

    }
  };

  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split('/')[1];

  const openHome = ()=>{
    window.open("https://www.energyumpire.com.au/")
  }

  const openMovingHouse = ()=>{
    window.open("https://www.energyumpire.com.au/moving-house/")
  }

  const openGreenPower = ()=>{
    window.open("https://www.energyumpire.com.au/green-power/")
  }

  const openBlog = ()=>{
    window.open("https://www.energyumpire.com.au/blog/")
  }

  const openFAQ = ()=>{
    window.open("https://www.energyumpire.com.au/faq/")
  }

  const openAboutUs= ()=>{
    window.open("https://www.energyumpire.com.au/about-us/")
  }

  const openContactUs = ()=>{
    window.open("https://www.energyumpire.com.au/contact-us/")
  }


  return (

    <Menu
      defaultOpenKeys={[defaultOpenKeys]}
      selectedKeys={[selectedKeys]}
      mode="horizontal">

      <SubMenu className={getNavStyleSubMenuClass(navStyle)} key="home"
               title="Home" onTitleClick={openHome} />


      <SubMenu className={getNavStyleSubMenuClass(navStyle)} key="whatYouGet"
               title="What you get">

        <Menu.Item key="whatYouGet/business">
          <a href="https://www.energyumpire.com.au/business-what-you-get/" target="_blank">
            <i className="icon icon-email"/>
            Business
          </a>
        </Menu.Item>

        <Menu.Item key="whatYouGet/business">
          <a href="https://www.energyumpire.com.au/homes-what-you-get/" target="_blank">
            <i className="icon icon-email"/>
            Home
          </a>
        </Menu.Item>
      </SubMenu>

      <SubMenu className={getNavStyleSubMenuClass(navStyle)} key="movingHouse"
               title="Moving House?" onTitleClick={openMovingHouse} />

      <SubMenu className={getNavStyleSubMenuClass(navStyle)} key="greenPower"
               title="Green Power" onTitleClick={openGreenPower} />

      <SubMenu className={getNavStyleSubMenuClass(navStyle)} key="blog"
               title="Blog" onTitleClick={openBlog} />

      <SubMenu className={getNavStyleSubMenuClass(navStyle)} key="FAQ"
               title="FAQ" onTitleClick={openFAQ} />

      <SubMenu className={getNavStyleSubMenuClass(navStyle)} key="aboutUs"
               title="About Us" onTitleClick={openAboutUs} />

      <SubMenu className={getNavStyleSubMenuClass(navStyle)} key="contactUs"
               title="Contact Us" onTitleClick={openContactUs} />



    </Menu>

  );
};

HorizontalNav_v1.propTypes = {};

export default HorizontalNav_v1;


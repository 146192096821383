const elec_details_col = [
  {
    title: "Rate",
    dataIndex: "usage_type",
    width: "40%",
    className: "isoTextCell",
    fixed: "left",
  },
  {
    title: "UNITS",
    dataIndex: "usage_units",
    width: "30%",
    className: "isoTextCell",
  },
  {
    title: "PRICE Inc. GST",
    dataIndex: "usage_price",
    width: "30%",
    className: "isoTextCell",
  },
];

const elec_details_col_discount = [
  {
    title: "Rate",
    dataIndex: "usage_type",
    width: 100,
    fixed: "left",
  },
  {
    title: "UNITS",
    dataIndex: "usage_units",
  },
  {
    title: "PRICE Inc. GST",
    dataIndex: "usage_price",
  },
  {
    title: "After Discount",
    dataIndex: "usage_discount",
  },
];

let formatPrice = (usageprice) => {
  return (usageprice * 100).toPrecision(4);
};

// Three Rate types
// Anytime rate type
let anytime = (pricing_item, peakNum) => {
  // common usage type
  if (pricing_item.usagetype === "service to property") {
    let table_row = {
      key: 1,
      usage_type: "Supply Charge",
      usage_units: " c/day",
      usage_price: formatPrice(pricing_item.usageprice),
    };
    return table_row;
  } else if (pricing_item.usagetype === "controlled") {
    let table_row = {
      key: 20,
      usage_type: "Controlled Load",
      usage_units: " c/kWh",
      usage_price: formatPrice(pricing_item.usageprice),
    };
    return table_row;
  } else if (pricing_item.usagetype === "controlled 2") {
    let table_row = {
      key: 20.1,
      usage_type: "Controlled Load 2",
      usage_units: " c/kWh",
      usage_price: formatPrice(pricing_item.usageprice),
    };
    return table_row;
  } else if (pricing_item.usagetype === "controlled service to property") {
    let table_row = {
      key: 20.2,
      usage_type: "Controlled Load Service to Property",
      usage_units: " c/kWh",
      usage_price: formatPrice(pricing_item.usageprice),
    };
    return table_row;
  } else if (pricing_item.usagetype === "controlled 2 service to property") {
    let table_row = {
      key: 20.3,
      usage_type: "Controlled Load 2 Service to Property",
      usage_units: " c/kWh",
      usage_price: formatPrice(pricing_item.usageprice),
    };
    return table_row;
  } else if (pricing_item.usagetype === "demand") {
    let table_row = {
      key: 21,
      usage_type: "Demand",
      usage_units: " c/kW",
      usage_price: formatPrice(pricing_item.usageprice),
    };
    return table_row;
  } else if (pricing_item.usagetype === "fit") {
    let table_row = {
      key: 22,
      usage_type: "Current Solar Feed In",
      usage_units: " c/kWh",
      usage_price: formatPrice(pricing_item.usageprice),
    };
    return table_row;
  } else if (pricing_item.usagetype === "fit 2") {
    let table_row = {
      key: 23,
      usage_type: "Premium Solar Feed In",
      usage_units: " c/kWh",
      usage_price: formatPrice(pricing_item.usageprice),
    };
    return table_row;
  } else if (pricing_item.usagetype === "green power") {
    let table_row = {
      key: 24,
      usage_type: "Green Power Fee",
      usage_units: " c/kWh",
      usage_price: formatPrice(pricing_item.usageprice),
    };
    return table_row;
  } else if (pricing_item.usagetype === "green power service to property") {
    let table_row = {
      key: 24,
      usage_type: "Green Power Fee",
      usage_units: " c/day",
      usage_price: formatPrice(pricing_item.usageprice),
    };
    return table_row;
  }
  // General Usage
  else if (pricing_item.usagetype === "peak") {
    // just one peak type
    if (peakNum < 2) {
      let table_row = {
        key: 5,
        usage_type: "General Usage",
        usage_units: " c/kWh",
        usage_price: formatPrice(pricing_item.usageprice),
      };
      return table_row;
    }
    // more than one peak
    else {
      // Step Number is 1
      if (pricing_item.stepno < 2) {
        let table_row = {
          key: pricing_item.stepno + 4,
          usage_type:
            "General Usage First " +
            pricing_item.maxthreshold.toFixed(2) +
            " kWh per day",
          usage_units: " c/kWh",
          usage_price: formatPrice(pricing_item.usageprice),
        };
        return table_row;
      }
      // Step Number is (2 -- Max)
      else if (pricing_item.stepno < peakNum) {
        let table_row = {
          key: pricing_item.stepno + 4,
          usage_type:
            "General Usage Next " +
            pricing_item.maxthreshold.toFixed(2) +
            " kWh per day",
          usage_units: " c/kWh",
          usage_price: formatPrice(pricing_item.usageprice),
        };
        return table_row;
      }
      // Step Number is Max
      else {
        let table_row = {
          key: pricing_item.stepno + 4,
          usage_type: "General Usage Balance ",
          usage_units: " c/kWh",
          usage_price: formatPrice(pricing_item.usageprice),
        };
        return table_row;
      }
    }
  } else {
    let table_row = {
      key: 99,
      usage_type: "Unknow Type",
      usage_price: "99999999",
    };
    return table_row;
  }
};

let gas = (pricing_item, peakNum) => {
  // common usage type
  if (pricing_item.usagetype === "service to property") {
    let table_row = {
      key: 1,
      usage_type: "Supply Charge",
      usage_units: " c/day",
      usage_price: formatPrice(pricing_item.usageprice),
    };
    return table_row;
  }
  // General Usage
  else if (pricing_item.usagetype === "peak") {
    // just one peak type
    if (peakNum < 2) {
      let table_row = {
        key: 5,
        usage_type: "General Usage",
        usage_units: " c/MJ",
        usage_price: formatPrice(pricing_item.usageprice),
      };
      return table_row;
    }
    // more than one peak
    else {
      // Step Number is 1
      if (pricing_item.stepno < 2) {
        let table_row = {
          key: pricing_item.stepno + 4,
          usage_type:
            "General Usage First " + pricing_item.maxthreshold + " MJ per day",
          usage_units: " c/MJ",
          usage_price: formatPrice(pricing_item.usageprice),
        };
        return table_row;
      }
      // Step Number is (2 -- Max)
      else if (pricing_item.stepno < peakNum) {
        let table_row = {
          key: pricing_item.stepno + 4,
          usage_type:
            "General Usage Next " + pricing_item.maxthreshold + " MJ per day",
          usage_units: " c/MJ",
          usage_price: formatPrice(pricing_item.usageprice),
        };
        return table_row;
      }
      // Step Number is Max
      else {
        let table_row = {
          key: pricing_item.stepno + 4,
          usage_type: "General Usage Balance ",
          usage_units: " c/MJ",
          usage_price: formatPrice(pricing_item.usageprice),
        };
        return table_row;
      }
    }
  } else {
    let table_row = {
      key: 99,
      usage_type: "Unknow Type",
      usage_price: "99999999",
    };
    return table_row;
  }
};

// Time of Use rate type
let timeOfuse = (pricing_item) => {
  // common usage type
  if (pricing_item.usagetype === "service to property") {
    let table_row = {
      key: 1,
      usage_type: "Supply Charge",
      usage_units: " c/day",
      usage_price: formatPrice(pricing_item.usageprice),
    };
    return table_row;
  } else if (pricing_item.usagetype === "controlled") {
    let table_row = {
      key: 20,
      usage_type: "Controlled Load",
      usage_units: " c/kWh",
      usage_price: formatPrice(pricing_item.usageprice),
    };
    return table_row;
  } else if (pricing_item.usagetype === "controlled 2") {
    let table_row = {
      key: 20.1,
      usage_type: "Controlled Load 2",
      usage_units: " c/kWh",
      usage_price: formatPrice(pricing_item.usageprice),
    };
    return table_row;
  } else if (pricing_item.usagetype === "controlled service to property") {
    let table_row = {
      key: 20.2,
      usage_type: "Controlled Load Service to Property",
      usage_units: " c/kWh",
      usage_price: formatPrice(pricing_item.usageprice),
    };
    return table_row;
  } else if (pricing_item.usagetype === "controlled 2 service to property") {
    let table_row = {
      key: 20.3,
      usage_type: "Controlled Load 2 Service to Property",
      usage_units: " c/kWh",
      usage_price: formatPrice(pricing_item.usageprice),
    };
    return table_row;
  } else if (pricing_item.usagetype === "demand") {
    let table_row = {
      key: 21,
      usage_type: "Demand",
      usage_units: " c/kW",
      usage_price: formatPrice(pricing_item.usageprice),
    };
    return table_row;
  } else if (pricing_item.usagetype === "fit") {
    let table_row = {
      key: 22,
      usage_type: "Current Solar Feed In",
      usage_units: " c/kWh",
      usage_price: formatPrice(pricing_item.usageprice),
    };
    return table_row;
  } else if (pricing_item.usagetype === "fit 2") {
    let table_row = {
      key: 23,
      usage_type: "Premium Solar Feed In",
      usage_units: " c/kWh",
      usage_price: formatPrice(pricing_item.usageprice),
    };
    return table_row;
  } else if (pricing_item.usagetype === "green power") {
    let table_row = {
      key: 24,
      usage_type: "Green Power Fee",
      usage_units: " c/kWh",
      usage_price: formatPrice(pricing_item.usageprice),
    };
    return table_row;
  } else if (pricing_item.usagetype === "green power service to property") {
    let table_row = {
      key: 24,
      usage_type: "Green Power Fee",
      usage_units: " c/day",
      usage_price: formatPrice(pricing_item.usageprice),
    };
    return table_row;
  }
  //Peak & Off-Peak
  else if (pricing_item.usagetype === "peak") {
    let table_row = {
      key: 5,
      usage_type: "Peak",
      usage_units: " c/kWh",
      usage_price: formatPrice(pricing_item.usageprice),
    };
    return table_row;
  } else if (pricing_item.usagetype === "off-peak") {
    let table_row = {
      key: 6,
      usage_type: "Off Peak",
      usage_units: " c/kWh",
      usage_price: formatPrice(pricing_item.usageprice),
    };
    return table_row;
  } else {
    let table_row = {
      key: 99,
      usage_type: "Unknow Type",
      usage_price: "99999999",
    };
    return table_row;
  }
};

// Fixable rate type
let fixable = (pricing_item) => {
  // common usage type
  if (pricing_item.usagetype === "service to property") {
    let table_row = {
      key: 1,
      usage_type: "Supply Charge",
      usage_units: " c/day",
      usage_price: formatPrice(pricing_item.usageprice),
    };
    return table_row;
  } else if (pricing_item.usagetype === "controlled") {
    let table_row = {
      key: 20,
      usage_type: "Controlled Load",
      usage_units: " c/kWh",
      usage_price: formatPrice(pricing_item.usageprice),
    };
    return table_row;
  } else if (pricing_item.usagetype === "controlled 2") {
    let table_row = {
      key: 20.1,
      usage_type: "Controlled Load 2",
      usage_units: " c/kWh",
      usage_price: formatPrice(pricing_item.usageprice),
    };
    return table_row;
  } else if (pricing_item.usagetype === "controlled service to property") {
    let table_row = {
      key: 20.2,
      usage_type: "Controlled Load Service to Property",
      usage_units: " c/kWh",
      usage_price: formatPrice(pricing_item.usageprice),
    };
    return table_row;
  } else if (pricing_item.usagetype === "controlled 2 service to property") {
    let table_row = {
      key: 20.3,
      usage_type: "Controlled Load 2 Service to Property",
      usage_units: " c/kWh",
      usage_price: formatPrice(pricing_item.usageprice),
    };
    return table_row;
  } else if (pricing_item.usagetype === "demand") {
    let table_row = {
      key: 21,
      usage_type: "Demand",
      usage_units: " c/kW",
      usage_price: formatPrice(pricing_item.usageprice),
    };
    return table_row;
  } else if (pricing_item.usagetype === "fit") {
    let table_row = {
      key: 22,
      usage_type: "Current Solar Feed In",
      usage_units: " c/kWh",
      usage_price: formatPrice(pricing_item.usageprice),
    };
    return table_row;
  } else if (pricing_item.usagetype === "fit 2") {
    let table_row = {
      key: 23,
      usage_type: "Premium Solar Feed In",
      usage_units: " c/kWh",
      usage_price: formatPrice(pricing_item.usageprice),
    };
    return table_row;
  } else if (pricing_item.usagetype === "green power") {
    let table_row = {
      key: 24,
      usage_type: "Green Power Fee",
      usage_units: " c/kWh",
      usage_price: formatPrice(pricing_item.usageprice),
    };
    return table_row;
  } else if (pricing_item.usagetype === "green power service to property") {
    let table_row = {
      key: 24,
      usage_type: "Green Power Fee",
      usage_units: " c/day",
      usage_price: formatPrice(pricing_item.usageprice),
    };
    return table_row;
  }
  //Peak & Off-Peak & Shoulder
  else if (pricing_item.usagetype === "peak") {
    let table_row = {
      key: 5,
      usage_type: "Peak",
      usage_units: " c/kWh",
      usage_price: formatPrice(pricing_item.usageprice),
    };
    return table_row;
  } else if (pricing_item.usagetype === "off-peak") {
    let table_row = {
      key: 6,
      usage_type: "Off Peak",
      usage_units: " c/kWh",
      usage_price: formatPrice(pricing_item.usageprice),
    };
    return table_row;
  } else if (pricing_item.usagetype === "shoulder") {
    let table_row = {
      key: 7,
      usage_type: "Shoulder",
      usage_units: " c/kWh",
      usage_price: formatPrice(pricing_item.usageprice),
    };
    return table_row;
  } else {
    let table_row = {
      key: 99,
      usage_type: "Unknow Type",
      usage_price: "99999999",
    };
    return table_row;
  }
};

let peakCount = (pricing_list) => {
  let peakList = [];
  let peakNum = 0;
  pricing_list.map((pricing_item) => {
    if (pricing_item.usagetype === "peak") {
      peakNum = peakList.push(pricing_item);
    }
  });
  return peakNum;
};

let gasPeakCount = (pricing_list) => {
  let peakNum = 0;
  pricing_list.map((pricing_item) => {
    if (pricing_item.stepno > peakNum) {
      peakNum = pricing_item.stepno;
    }
  });
  return peakNum;
};

let isDiscounted = (pricing_list) => {
  let discount_type = "";
  let discount_price = 0;
  pricing_list.map((pricing_item) => {
    if (
      pricing_item.usagetype === "discount" &&
      (pricing_item.discounttype.toLowerCase() === "usage" ||
        pricing_item.discounttype.toLowerCase() === "all")
    ) {
      discount_price = pricing_item.usageprice;
      discount_type = pricing_item.discounttype;
    }
  });

  return [discount_price, discount_type];
};

let listDiscounts = (pricing_list) => {
  let discount_list = pricing_list.filter((pricing_item) => {
    if (pricing_item.usagetype === "discount") {
      return [pricing_item.usageprice, pricing_item.discounttype];
    }
  });

  discount_list = discount_list.map((pricing_item) => {
    return [pricing_item.usageprice, pricing_item.discounttype];
  });

  return discount_list;
};

let rowAfterDiscount = (table_row, discount_rate, discount_type) => {
  let usage_discount = 0;
  if (!table_row) return null;

  if (table_row.key === 22 || table_row.key === 23 || table_row.key === 24) {
    usage_discount = table_row.usage_price * 1;
  } else {
    if (discount_type !== "usage") {
      usage_discount = table_row.usage_price * (1 - discount_rate);
    } else if (table_row.key !== 1) {
      usage_discount = table_row.usage_price * (1 - discount_rate);
    } else {
      usage_discount = table_row.usage_price * 1;
    }
  }
  //
  table_row["usage_discount"] = usage_discount.toPrecision(4);

  return table_row;
};

let getElecTabs = (pricing_list) => {
  let tab_name_lists = [];
  let empty_elec_tabs = [];
  let isOneYear = isOneYearFunc(pricing_list);
  pricing_list.map((pricing_item) => {
    let tab_name = pricing_item.startmonth + " - " + pricing_item.endmonth;
    if (pricing_item.usagetype !== "discount") {
      tab_name_lists.push(tab_name);
    }
  });
  if (isOneYear) {
    tab_name_lists = ["JAN - DEC"];
  }

  let newList = Array.from(new Set(tab_name_lists));
  let tab_key = 1;
  newList.map((tab_name) => {
    let elecTab = {
      key: tab_key,
      name: tab_name,
      table_info: [],
    };
    tab_key += 1;
    empty_elec_tabs.push(elecTab);
  });
  return empty_elec_tabs;
};

const isOneYearFunc = (pricing_list) => {
  let isOneYearList = [];
  let isOneYear = true;
  pricing_list.map((pricing_item) => {
    isOneYearList = [
      ...isOneYearList,
      timeSpan(pricing_item.startmonth, pricing_item.endmonth),
    ];
  });
  //if one item is not 12months, it is not one year.
  isOneYearList.map((item) => {
    if (item === false) {
      isOneYear = false;
    }
  });
  return isOneYear;
};

const timeSpan = (startMonth, endMonth) => {
  let fullYear = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];
  let startNum = fullYear.indexOf(startMonth) + 1;
  let endNum = fullYear.indexOf(endMonth) + 1;
  return endNum - startNum === -1 || endNum - startNum === 11;
};

let getOfferDetails = (res) => {
  let offerDetails = [];
  if (res.greenpower) {
    offerDetails = [
      {
        label: "Plan Name",
        value: res.name,
      },
      {
        label: "Distributor",
        value: res.distributor,
      },
      {
        label: "Supplier",
        value: res.retailer,
      },
      {
        label: "Rate Type",
        value: res.ratetype,
      },
      {
        label: "Green Power Percent",
        value: `${res.greenpowerpercent} %`,
      },
      {
        label: "Active Date",
        value: res.activedate.slice(0, 10),
      },
    ];
  } else {
    offerDetails = [
      {
        label: "Plan Name",
        value: res.name,
      },
      {
        label: "Distributor",
        value: res.distributor,
      },
      {
        label: "Supplier",
        value: res.retailer,
      },
      {
        label: "Rate Type",
        value: res.ratetype,
      },
      {
        label: "Active Date",
        value: res.activedate.slice(0, 10),
      },
    ];
  }
  return offerDetails;
};

let getFeatures = (res) => {
  let features = res.features;
  let features_list = [];
  features.map((item) => {
    let feature_list = item.split(" - ");
    let each_feature = {
      label: feature_list[0],
      value: feature_list[1],
    };
    features_list.push(each_feature);
  });
  return features_list;
};

let formatTableInfo = (res) => {
  let pricing_list = res.pricing;
  let elec_details_tabs = getElecTabs(pricing_list);
  let default_table_config = [];
  // if discount exists, return discount rate, otherwise return 0
  let discount = isDiscounted(pricing_list);
  let discount_rate = discount[0];
  let discount_type = discount[1];

  const isOneYear = isOneYearFunc(pricing_list);

  if (res.ratetype === "Anytime") {
    //console.log('Anytime discount_rate' + discount_rate);
    let peakNum = peakCount(pricing_list);
    if (discount_rate === 0) {
      default_table_config = elec_details_col;
      pricing_list.map((pricing_item) => {
        let tab_name = pricing_item.startmonth + " - " + pricing_item.endmonth;
        // fill table in each tab
        elec_details_tabs.map((tab) => {
          if (isOneYear) {
            let table_row = anytime(pricing_item, peakNum);
            if (table_row.key !== 99) {
              tab.table_info.push(table_row);
            }
          } else if (tab_name === tab.name) {
            let table_row = anytime(pricing_item, peakNum);
            if (table_row.key !== 99) {
              tab.table_info.push(table_row);
            }
          }
        });
      });
    } else {
      default_table_config = elec_details_col_discount;

      pricing_list.map((pricing_item) => {
        let tab_name = pricing_item.startmonth + " - " + pricing_item.endmonth;
        // fill table in each tab
        elec_details_tabs.map((tab) => {
          if (isOneYear) {
            let table_row = anytime(pricing_item, peakNum);

            table_row = rowAfterDiscount(
              table_row,
              discount_rate,
              discount_type
            );
            if (table_row.key !== 99) {
              tab.table_info.push(table_row);
            }
          } else if (tab_name === tab.name) {
            let table_row = anytime(pricing_item, peakNum);

            table_row = rowAfterDiscount(
              table_row,
              discount_rate,
              discount_type
            );
            if (table_row.key !== 99) {
              tab.table_info.push(table_row);
            }
          }
        });
      });
    }
  } else if (res.ratetype === "Gas") {
    let peakNum = gasPeakCount(pricing_list);
    if (discount_rate === 0) {
      default_table_config = elec_details_col;
      pricing_list.map((pricing_item) => {
        let tab_name = pricing_item.startmonth + " - " + pricing_item.endmonth;
        //console.log("isOneYear", isOneYear);
        // fill table in each tab
        elec_details_tabs.map((tab) => {
          if (isOneYear) {
            let table_row = gas(pricing_item, peakNum);
            if (table_row.key !== 99) {
              tab.table_info.push(table_row);
            }
          } else if (tab_name === tab.name) {
            let table_row = gas(pricing_item, peakNum);
            if (table_row.key !== 99) {
              tab.table_info.push(table_row);
            }
          }
        });
      });
    } else {
      default_table_config = elec_details_col_discount;
      pricing_list.map((pricing_item) => {
        let tab_name = pricing_item.startmonth + " - " + pricing_item.endmonth;
        // fill table in each tab
        elec_details_tabs.map((tab) => {
          if (isOneYear) {
            let table_row = gas(pricing_item, peakNum);

            table_row = rowAfterDiscount(
              table_row,
              discount_rate,
              discount_type
            );
            if (table_row.key !== 99) {
              tab.table_info.push(table_row);
            }
          } else if (tab_name === tab.name) {
            let table_row = gas(pricing_item, peakNum);

            table_row = rowAfterDiscount(
              table_row,
              discount_rate,
              discount_type
            );
            if (table_row.key !== 99) {
              tab.table_info.push(table_row);
            }
          }
        });
      });
    }
  } else if (res.ratetype === "Time Of Use") {
    //console.log('Time of Use discount_rate' + discount_rate);
    if (discount_rate == 0) {
      default_table_config = elec_details_col;
      pricing_list.map((pricing_item) => {
        let tab_name = pricing_item.startmonth + " - " + pricing_item.endmonth;
        // fill table in each tab
        elec_details_tabs.map((tab) => {
          if (isOneYear) {
            let table_row = timeOfuse(pricing_item);
            if (table_row.key !== 99) {
              tab.table_info.push(table_row);
            }
          } else if (tab_name === tab.name) {
            let table_row = timeOfuse(pricing_item);
            if (table_row.key !== 99) {
              tab.table_info.push(table_row);
            }
          }
        });
      });
    } else {
      default_table_config = elec_details_col_discount;
      pricing_list.map((pricing_item) => {
        let tab_name = pricing_item.startmonth + " - " + pricing_item.endmonth;
        // fill table in each tab
        elec_details_tabs.map((tab) => {
          if (isOneYear) {
            let table_row = timeOfuse(pricing_item);

            table_row = rowAfterDiscount(
              table_row,
              discount_rate,
              discount_type
            );
            if (table_row.key !== 99) {
              tab.table_info.push(table_row);
            }
          } else if (tab_name === tab.name) {
            let table_row = timeOfuse(pricing_item);

            table_row = rowAfterDiscount(
              table_row,
              discount_rate,
              discount_type
            );
            if (table_row.key !== 99) {
              tab.table_info.push(table_row);
            }
          }
        });
      });
    }
  } else {
    if (discount_rate === 0) {
      default_table_config = elec_details_col;
      pricing_list.map((pricing_item) => {
        let tab_name = pricing_item.startmonth + " - " + pricing_item.endmonth;
        // fill table in each tab
        elec_details_tabs.map((tab) => {
          if (isOneYear) {
            let table_row = fixable(pricing_item);
            tab.table_info.push(table_row);
          } else if (tab_name === tab.name) {
            let table_row = fixable(pricing_item);
            tab.table_info.push(table_row);
          }
        });
      });
    } else {
      default_table_config = elec_details_col_discount;
      pricing_list.map((pricing_item) => {
        let tab_name = pricing_item.startmonth + " - " + pricing_item.endmonth;
        // fill table in each tab
        elec_details_tabs.map((tab) => {
          if (isOneYear) {
            let table_row = fixable(pricing_item);

            table_row = rowAfterDiscount(
              table_row,
              discount_rate,
              discount_type
            );
            if (table_row.key !== 99) {
              tab.table_info.push(table_row);
            }
          } else if (tab_name === tab.name) {
            let table_row = fixable(pricing_item);

            table_row = rowAfterDiscount(
              table_row,
              discount_rate,
              discount_type
            );

            if (table_row.key !== 99) {
              tab.table_info.push(table_row);
            }
          }
        });
      });
    }
  }
  // sort by key
  elec_details_tabs.map((tab) => {
    tab.table_info.sort((val1, val2) => {
      return val1.key - val2.key;
    });
  });
  return {
    tabs: elec_details_tabs,
    config: default_table_config,
  };
};

export {
  formatTableInfo,
  getOfferDetails,
  getFeatures,
  isDiscounted,
  listDiscounts,
};

import React from "react";
import {Route, Switch} from "react-router-dom";

import asyncComponent from "util/asyncComponent";

const App = ({match}) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route path={`${match.url}premium`} component={asyncComponent(() => import('./Premium'))}/>
      <Route path={`${match.url}dashboard`} component={asyncComponent(() => import('./Dashboard'))}/>
      <Route path={`${match.url}best_plan`} component={asyncComponent(() => import('./BestPlan'))}/>
      <Route path={`${match.url}BestPlan/:planId`} component={asyncComponent(() => import('./BestPlan_v1'))}/>
      <Route path={`${match.url}PlanDetails/:planId`} component={asyncComponent(() => import('./PlanDetails'))}/>
      <Route path={`${match.url}SwitchPlan/:planId`} component={asyncComponent(() => import('./SwitchPlan/SwitchPlan_v1'))}/>
    </Switch>
  </div>
);

export default App;

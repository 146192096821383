import {
  FETCH_PLAN,
  FETCH_ACCOUNTS,
  FETCH_ALL_CUSTOMERS,
  FETCH_ACCOUNT_PLAN,
  FETCH_EMAIL,
} from "../../constants/ActionTypes";
import axios from 'axios';

export const fetchAccountPlan = (accountId) => {
  console.log("plan action", accountId)
  return {type: FETCH_ACCOUNT_PLAN, accountId}
};

export const fetchAllCustomers = () => {
  return (dispatch) => {
    axios
      .get('All',{
        baseURL: 'https://admin.energyumpire.com.au/API/Account/',
        timeout: 15000,
        headers: {
          Authorization: 'JWT eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhcHBfaWQiOiI1N2FkMGQ2OWY5NWIzYzcxMTZlMzQ5ZGEifQ.a6TaEW_9FqQjnT_mm2ySJuz4StCW4bBfFmdTDOt4Uk4',
          'Content-Type': 'application/json',
        },
      })
      .then(res => {
        console.log(res);
        const allCustomersInfo = res;
        dispatch({type: FETCH_ALL_CUSTOMERS, allCustomersInfo});
      })
  }
}



export const fetchPlan = (planId) => {
  return (dispatch) => {
    axios
      .get(planId, {
        baseURL: 'https://admin.energyumpire.com.au/API/EnergyPlan/',
        timeout: 15000,
        headers: {
          Authorization: 'JWT eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhcHBfaWQiOiI1ZjExMjg2YzZhMmRhYjAwMGUyZWMxNzgifQ.3BO4xflXDju_lKS_78ihWUikLMkg61saxXIMdFPLVPs',
          'Content-Type': 'application/json',
        },
      })
      .then(res => {
        console.log(res.data[0])
        const planInfo = res.data[0]
        dispatch({type: FETCH_PLAN, planInfo});
      })
  }
}

export const fetchAccounts = (customerId) => {
  return (dispatch) => {
    axios
      .get(customerId, {
        baseURL: 'https://admin.energyumpire.com.au/API/Contract/AccountId/',
        timeout: 25000,
        headers: {
          Authorization: 'JWT eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhcHBfaWQiOiI1YTI1M2RiMmM5NDk5NjJhYjM2MGJiMzUifQ.KTXKQPua_iL3PYBVBH2kpXdn9_ho_KOudFVDeFoLTMQ',
          'Content-Type': 'application/json',
        },
      })
      .then(res => {
        const accountsInfo = res.data
        console.log("axios accounts --->>", accountsInfo)
        dispatch({type: FETCH_ACCOUNTS, accountsInfo});
      })
  }
}
export const fetchEmails = (accountId) => {
  return (dispatch) => {
    axios
      .get(accountId, {
        baseURL: 'https://admin.energyumpire.com.au/API/Account/getId/',
        timeout: 25000,
        headers: {
          'Authorization': 'JWT eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhcHBfaWQiOiI1YTI1M2RiMmM5NDk5NjJhYjM2MGJiMzUifQ.KTXKQPua_iL3PYBVBH2kpXdn9_ho_KOudFVDeFoLTMQ',
          'Content-Type': 'application/json',
        },
      })
      .then(res => {
        const emailInfo = res.data
        console.log("axios emailInfo --->>", emailInfo)
        dispatch({type: FETCH_EMAIL, emailInfo});
      })
  }
}

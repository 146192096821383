import React from "react";
import {Button} from "antd";
import IntlMessages from "../../util/IntlMessages";
import {Link} from "react-router-dom";

const AppsNavigation = () =>
  <ul className="gx-app-nav">
    <li>
      <Button className="gx-btn gx-btn-orange gx-mb-0 gx-mr-0 gx-text-uppercase">
        <Link to="/premium">
          <span>GET PREMIUM NOW!</span>
        </Link>
      </Button>
    </li>
  </ul>;

export default AppsNavigation;
